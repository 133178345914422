<script lang="ts">
    import { getContext } from "svelte";
    import type { Writable } from "svelte/store";
    import { Routes, type Attempt } from "@lib/types";
    import { PuzzleDate } from "@lib/util";
    import { db } from "@lib/db";
    import Dot from "@icons/Dot.svelte";

    let router = getContext<Writable<Routes>>("router");
    let date = getContext<Writable<PuzzleDate>>("date");

    interface Props {
        puzzleDate: PuzzleDate;
        disabled: boolean;
    }

    let { puzzleDate, disabled }: Props = $props();

    let attempt: Attempt | undefined = $state(undefined);

    const select = (selectedDate: PuzzleDate) => {
        date.set(selectedDate);
        router.set(Routes.Menu);
        window.history.pushState({ page: Routes.Menu, date: $date }, "Menu", "");
    };

    const fetchAttempt = async (puzzleDate: PuzzleDate) => {
        try {
            const puzzle = await db.puzzles.get({ date: puzzleDate.iso });
            if (puzzle) {
                attempt = await db.attempts.get({ puzzle_id: puzzle.id });
            }
        } catch (e) {
            // noop
        }
    };

    fetchAttempt(puzzleDate);
</script>

<div class={attempt?.solved ? "square done" : "square"}>
    {#if disabled}
        <div class="disabled">
            <Dot />
        </div>
    {:else if puzzleDate.isFuture()}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
          <path fill-rule="evenodd" d="M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z" clip-rule="evenodd" />
        </svg>
    {:else}
        <button
            onclick={() => select(puzzleDate)}
            type="button"
        >
            <time datetime={puzzleDate.iso}>
                {puzzleDate.date.getUTCDate()}
            </time>
            <div class="state">
                {#if attempt}
                    {#if attempt.solved}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4">
                          <path fill-rule="evenodd" d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z" clip-rule="evenodd" />
                        </svg>
                    {:else}
                        ~
                    {/if}
                {:else}
                    -
                {/if}
            </div>
        </button>
    {/if}
</div>

<style>
    .square {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid var(--black);
        border-radius: 4px;
        aspect-ratio: 1;
    }

    .square.done {
        background-color: var(--cell-yellow);
    }

    button {
        height: 100%;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        line-height: 0.5em;
        padding: 0;
        border: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background-color: transparent;
        cursor: pointer;
    }

    .state {
        align-content: center;
    }

    svg, .disabled, .state {
        width: 16px;
        height: 16px;
    }
</style>
