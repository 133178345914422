<script lang="ts">
    import { getContext, onMount } from "svelte";
    import type { Writable } from "svelte/store";
    import type { Timer } from "./types";
    import { settings } from "./store";
    import { formatTime } from "./util";
    import PauseButton from "./PauseButton.svelte";

    const timer = getContext<Writable<Timer>>("timer");
    let now = $state(Date.now());

    const calculateElapsed = (since: number) => {
        return Date.now() - since;
    };

    onMount(() => {
        const interval = setInterval(() => {
            timer.update((t) => {
                if (t.paused) {
                    return t;
                } else {
                    return {
                        ...t,
                        elapsed: calculateElapsed(now - t.elapsed),
                    };
                }
            });
            now = Date.now();
        }, 1000);

        return () => clearInterval(interval);
    });
</script>

<section>
    <label class="monospace" for="pause">
        {#if $settings.hide_timer}
            &squf;&squf;:&squf;&squf;
        {:else}
            {formatTime($timer.elapsed)}
        {/if}
    </label>
    <PauseButton />
</section>

<style>
    section {
        display: flex;
        align-items: center;
    }

    label {
        cursor: pointer;
        font-weight: 500;
    }
</style>
