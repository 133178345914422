<script lang="ts">
    import { settings } from "./store";
    import LightBulbIcon from "./icons/LightBulb.svelte";
    import NextIcon from "./icons/Next.svelte";

    const tips = [
        "Press and drag to mark multiple cells at once.",
        "Markers are useful for tracking where kings cannot be placed.",
        "The undo and redo actions will jump between king placements.",
        "Hints can be used to reveal the next king placement.",
    ];

    let current = $state(Math.floor(Math.random() * tips.length));

    const nextTip = () => {
        current = current === tips.length - 1 ? 0 : current + 1;
    };
</script>

{#if !$settings.hide_tips}
    <section>
        <div class="icon small">
            <LightBulbIcon />
        </div>
        <p>
            <b>TIP:</b>
            {tips[current]}
        </p>
        <button class="icon small" onclick={nextTip}>
            <NextIcon />
        </button>
    </section>
{/if}

<style>
    section {
        display: grid;
        grid-template-columns: 24px 1fr 24px;
        background-color: var(--accent-color);
        color: var(--black);
        border: 2px solid var(--black);
        border-radius: 8px;
        padding: 0.5rem;
        margin: 0.5rem 0;
    }

    section > .icon {
        margin-top: -3px;
    }

    section > p {
        flex-grow: 1;
    }

    section > button.icon.small {
        align-self: center;
        margin-left: auto;
        color: var(--black);
    }
</style>
