<script lang="ts">
    import { settings } from "./store";
    import SettingsButton from "./SettingsButton.svelte";

    let show = $state(false);

    const updateSetting = (key: string, value: boolean) => {
        settings.update((s) => ({ ...s, [key]: value }));
    };
</script>

<div>
    <SettingsButton onclick={() => (show = !show)} />
    <dialog open={show}>
        <div class="setting">
            <h4>Dark mode</h4>
            <label>
                <input
                    type="checkbox"
                    checked={$settings.dark_mode}
                    onchange={(e) => updateSetting("dark_mode", e.target.checked)}
                />
                <span></span>
            </label>
        </div>
        <div class="setting">
            <h4>Hide timer</h4>
            <label>
                <input
                    type="checkbox"
                    checked={$settings.hide_timer}
                    onchange={(e) => updateSetting("hide_timer", e.target.checked)}
                />
                <span></span>
            </label>
        </div>
        <div class="setting">
            <h4>Hide tips</h4>
            <label>
                <input
                    type="checkbox"
                    checked={$settings.hide_tips}
                    onchange={(e) => updateSetting("hide_tips", e.target.checked)}
                />
                <span></span>
            </label>
        </div>
    </dialog>
</div>

<style>
    dialog {
        font-family: "Oswald", sans-serif;
        z-index: 1;
        left: auto;
        margin-right: 1rem;
        width: 240px;
    }

    .setting {
        display: flex;
        justify-content: space-between;
        margin: 4px 0;
    }

    label {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 28px;
    }

    label input[type="checkbox"] {
        opacity: 0;
        width: 0;
        height: 0;
    }

    label span {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--white);
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 24px;
        border: 2px solid var(--black);
    }

    label span::before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 3px;
        bottom: 2px;
        background-color: var(--black);
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
    }

    label input:checked + span {
        background-color: var(--cell-yellow);
    }

    label input:focus + span {
        box-shadow: 0 0 1px var(--cell-yellow);
    }

    label input:checked + span:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
    }
</style>
