<script lang="ts">
    import { type Attempt, type Puzzle, type Stats } from "@lib/types";
    import { formatTime, isSolved } from "@lib/util";
    import { solve } from "@lib/api";

    interface Props {
        puzzle: Puzzle;
        attempt: Attempt;
    }

    let { puzzle, attempt }: Props = $props();

    const loadSummary = async (): Promise<Stats> => {
        const solution = isSolved(puzzle, attempt.placements);
        if (!solution) throw new Error("Invalid solution");

        const solveTime = attempt.timer.elapsed;
        return solve(attempt.session, solution, solveTime);
    };
</script>

<section>
    <div class="oswald fade-in stats">
        {#await loadSummary()}
            <div class="stat">
                <p>Puzzle</p>
                <div class="spacer"></div>
                <p class="pulse">####</p>
            </div>
            <div class="stat">
                <p>Hints used</p>
                <div class="spacer"></div>
                <p class="pulse">#</p>
            </div>
            <div class="stat">
                <p>Your time</p>
                <div class="spacer"></div>
                <p class="pulse">#:##</p>
            </div>
            <div class="stat">
                <p>Average time</p>
                <div class="spacer"></div>
                <p class="pulse">#:##</p>
            </div>
            <div class="stat">
                <p>Attempts</p>
                <div class="spacer"></div>
                <p class="pulse">####</p>
            </div>
            <div class="stat">
                <p>Completion rate</p>
                <div class="spacer"></div>
                <p class="pulse">##%</p>
            </div>
        {:then stats}
            <div class="stat">
                <p>Puzzle</p>
                <div class="spacer"></div>
                <p>#{puzzle.id}</p>
            </div>
            <div class="stat">
                <p>Hints used</p>
                <div class="spacer"></div>
                <p>{stats.hints_used}</p>
            </div>
            <div class="stat">
                <p>Your time</p>
                <div class="spacer"></div>
                <p>{formatTime(stats.solve_time)}</p>
            </div>
            <div class="stat">
                <p>Average time</p>
                <div class="spacer"></div>
                <p>{formatTime(stats.avg_time)}</p>
            </div>
            <div class="stat">
                <p>Attempts</p>
                <div class="spacer"></div>
                <p>{stats.attempts_counter}</p>
            </div>
            <div class="stat">
                <p>Completion rate</p>
                <div class="spacer"></div>
                <p>
                    {Math.round(
                        (stats.solved_counter / stats.attempts_counter) * 100,
                    )}%
                </p>
            </div>
        {:catch}
            Failed to load stats.
        {/await}
    </div>
</section>

<style>
    .stats {
        width: 256px;
        padding: 0.5rem;
        border: 2px solid var(--black);
        border-radius: 8px;
    }

    .stat {
        display: flex;
    }

    .stat .spacer {
        flex-grow: 1;
        border-bottom: 2px dotted var(--black);
        margin-bottom: 4px;
    }
</style>
